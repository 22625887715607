'use client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppProvider } from '../contexts';
import { GlobalStyles } from '../styles/GlobalStyles';
import './global.css';
import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import Script from 'next/script';
import NextTopLoader from 'nextjs-toploader';
import { usePathname } from 'next/navigation';
import { validateLocale } from '../utils/locale';
import { CookieConsentBanner, Futty } from '@sal-solution/ui-web';
import { useCurrentLocale } from '@/hooks/useLocal';
import { FacebookPixel } from '@/components/FacebookPixel/FacebookPixel';
import RootStyleRegistry from './emotion';
import { useHojar } from '@/hooks/useHojar';

export default function RootLayout({
  children,
  params,
}: {
  children: React.ReactNode;
  params: { locale?: string };
}) {
  useHojar();
  const pathname = usePathname();
  const localeInPath = validateLocale(pathname.split('/')[1]);
  const locale = useCurrentLocale();
  return (
    <html lang={localeInPath} data-theme="light">
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="google-adsense-account" content="ca-pub-1911122742234734" />
        <meta
          name="google-site-verification"
          content="tEm3d3tGOBr7GY2ZtUg6Hjomqtzokv4ej2N4XrmE1XY"
        />
        <GoogleAnalytics gaId="G-P6NWR5XF1S" />
        <GoogleTagManager gtmId="GTM-MF8XTFGN" />
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10833959434"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        };
        gtag('js', new Date());
        `,
          }}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `gtag('config', 'AW-10833959434');`,
          }}
        ></script>
        <FacebookPixel />
      </head>
      <RootStyleRegistry>
        <GlobalStyles />
        <body>
          <NextTopLoader />
          <AppProvider>
            {children}
            <ToastContainer />
          </AppProvider>
          <Futty futtyKey="66616da258400" />
          <CookieConsentBanner locale={locale} />
        </body>
      </RootStyleRegistry>

      {/* <Script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="c6ad22fc-06ef-4741-a890-6cb175f0b26a"
        data-blockingmode="auto"
        type="text/javascript"
      /> */}
    </html>
  );
}
